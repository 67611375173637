import { ISettingsContextValue } from '@wix/yoshi-flow-editor/build/cjs/tpa-settings';

type GetContentParams = {
  settings: ISettingsContextValue;
  settingsParam: any;
};

export const getContent = ({ settings, settingsParam }: GetContentParams) => {
  return settings.get(settingsParam) || settings.getDefaultValue(settingsParam);
};
