import React, { FC } from 'react';
import Text, { TextType } from '../../../Text/Text';
import { TagName } from '../../../../../utils/tagName.const';
import { classes } from './PriceSummaryItem.st.css';
import { PriceSummaryDataHooks } from './dataHooks';
import { PriceUtils } from '@wix/bookings-uou-mappers';

export type PriceSummaryItemProps = {
  type: TextType;
  label: string;
  price: number;
  locale: string;
  currency: string;
  quantity?: number;
};

export const PriceSummaryItem: FC<PriceSummaryItemProps> = ({
  type,
  label,
  price,
  locale,
  currency,
  quantity,
}) => {
  const descriptionPrefix = quantity ? `${quantity} X ` : '';
  const description = PriceUtils.getFormattedCurrency({
    price,
    locale,
    currency,
  });
  const ariaDescription = PriceUtils.getFormattedCurrency({
    price,
    locale,
    currency,
    currencyDisplay: 'name',
  });

  return (
    <div className={classes.root} data-hook={PriceSummaryDataHooks.Item}>
      <Text
        type={type}
        tagName={TagName.Span}
        data-hook={PriceSummaryDataHooks.Label}
      >
        {label}
      </Text>
      <Text
        className={classes.description}
        type={type}
        data-hook={PriceSummaryDataHooks.Description}
        tagName={TagName.Span}
        aria-label={`${descriptionPrefix}${ariaDescription}`}
      >
        {descriptionPrefix}
        {description}
      </Text>
    </div>
  );
};
