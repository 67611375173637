import React from 'react';
import { classes, st } from './BackButton.st.css';
import { BackButtonDataHooks } from './dataHooks';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';
import ArrowLeft from 'wix-ui-icons-common/on-stage/ArrowLeftSmall';
import ArrowRight from 'wix-ui-icons-common/on-stage/ArrowRightSmall';

export interface BackButtonProps {}
const BackButton: React.FC<BackButtonProps> = () => {
  const { t } = useTranslation();
  const { isRTL, isMobile } = useEnvironment();
  return (
    <div
      className={st(classes.root, {
        isMobile,
      })}
    >
      <TextButton
        priority={TEXT_BUTTON_PRIORITY.primary}
        className={classes.backButton}
        data-hook={BackButtonDataHooks.BACK_BUTTON}
        onClick={() => window.history.back()}
        prefixIcon={
          isRTL ? <ArrowLeft aria-hidden /> : <ArrowRight aria-hidden />
        }
      >
        {t('app.back-button.label')}
      </TextButton>
    </div>
  );
};

export default BackButton;
