import React from 'react';
import { TextButton } from 'wix-ui-tpa/TextButton';
import { UserSelectionDataHooks } from './dataHooks';
import { classes } from './UserSelection.st.css';
import { useFormActions } from '../../Hooks/useFormActions';
import { Trans } from '@wix/yoshi-flow-editor';
import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import { Member } from '@wix/ambassador-members-ng-api/types';

export interface UserSelectionProps {
  memberDetails?: Member;
}
export const UserSelection: React.FC<UserSelectionProps> = ({
  memberDetails,
}) => {
  const { promptLogin, onLogout } = useFormActions();
  const userFirstName = memberDetails?.contact?.firstName;
  const isLoggedIn = memberDetails !== undefined;

  const handleUserSelection = () => {
    if (isLoggedIn) {
      onLogout();
    }
    promptLogin();
  };

  return (
    <div
      data-hook={UserSelectionDataHooks.USER_SELECTION_COMPONENT}
      className={classes.root}
    >
      <SectionNotification
        size="compact"
        type="wired"
        className={classes.notification}
        data-hook={UserSelectionDataHooks.USER_SELECTION_SECTION_NOTIFICATION}
      >
        <SectionNotification.Text>
          <Trans
            i18nKey={getUserSelectionTranslationKey(isLoggedIn, userFirstName)}
            values={{ name: userFirstName }}
          >
            <TextButton
              data-hook={UserSelectionDataHooks.USER_SELECTION_BUTTON}
              className={classes.link}
              onClick={() => handleUserSelection()}
            />
          </Trans>
        </SectionNotification.Text>
      </SectionNotification>
    </div>
  );
};

function getUserSelectionTranslationKey(
  isLoggedIn: boolean,
  userFirstName: string | undefined,
): string {
  if (isLoggedIn) {
    if (userFirstName) {
      return 'app.switch-user';
    }
    return 'app.switch-user-constant';
  }
  return 'app.login';
}
